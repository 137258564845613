import React from 'react';
import '../stylesheets/Empresa.css';


function Empresa(props) {
    
    const link = 'https://www.tesysweb.com/grupogalvense/';

    return (
        <a className='cuadro-empresa' rel='noreferrer' href={link + props.link} target='_blank'>
            <img 
                className='imagen-empresa' 
                src={link + '/images/logo' + props.imagen} 
                alt={'Imagen ' + props.nombre} 
            />
            <p className='contenedor-texto-empresa'>  {props.texto}</p>
        </a>
    )
}

export default Empresa;