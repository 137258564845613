
import './App.css';
import Empresa from './components/Empresa';

function App() {

  return (
    <div className="App">
        <div className='contenedor-logo-grupo'>
        <img 
            className='imagen-grupo'
            src='./images/logoGrupo.png' 
            alt='Logo Grupo' 
        />
        </div>
        <div className='contenedor-empresas'>
          <div className='fila'>
              <Empresa
              nombre='Cuna de la Bandera'
              imagen='Cuna.png'
              texto='Gestión de Ventas y Compras' 
              link='cunabandera'
              />
              <Empresa
              nombre='Viajes y Turismo'
              imagen='vyt.png'
              texto='Gestión de Ventas y Compras' 
              link='turismo'
              />
          </div>

          <div className='fila'>
              <Empresa 
              nombre='RTO'
              imagen='RTO.png'
              texto='Gestión de Ventas y Compras' 
              link='rto/admin'
              />
              <Empresa 
              nombre='RTO' 
              imagen='RTO.png'
              texto='S.A.M. (Acciones de Mejoras)' 
              link='rto'
              />
          </div>
          <div className='fila'>
              <Empresa
              nombre='GNC'
              imagen='GNC.png'
              texto='Gestión de Ventas y Compras' 
              link='gnc'
              />
              <Empresa 
              nombre='ITALIANI'
              imagen='ITALIANI.jpeg'
              texto='Gestión de Ventas y Compras' 
              link='italiani'
              />
          </div>
        </div>
    </div>
  );
}

export default App;
